@import '../form-field/form-field.component.theme';

@mixin select-filled {
  nb-select.appearance-filled .select-button {
    border-style: nb-theme(select-filled-border-style);
    border-width: nb-theme(select-filled-border-width);
  }

  @each $size in nb-get-sizes() {
    nb-select.appearance-filled.size-#{$size} .select-button {
      padding: nb-theme(select-filled-#{$size}-padding);
      @include nb-ltr(padding-right, nb-theme(select-icon-offset));
      @include nb-rtl(padding-left, nb-theme(select-icon-offset));
    }

    @include nb-form-field-with-prefix('nb-select.appearance-filled.size-#{$size} .select-button', $size);
  }

  @each $status in nb-get-statuses() {
    nb-select.appearance-filled.status-#{$status} .select-button {
      background-color: nb-theme(select-filled-#{$status}-background-color);
      border-color: nb-theme(select-filled-#{$status}-border-color);
      color: nb-theme(select-filled-#{$status}-text-color);

      &.placeholder {
        color: nb-theme(select-filled-#{$status}-placeholder-text-color);
      }

      &:focus {
        background-color: nb-theme(select-filled-#{$status}-focus-background-color);
        border-color: nb-theme(select-filled-#{$status}-focus-border-color);
      }
      &:hover {
        background-color: nb-theme(select-filled-#{$status}-hover-background-color);
        border-color: nb-theme(select-filled-#{$status}-hover-border-color);
      }

      &[disabled] {
        background-color: nb-theme(select-filled-#{$status}-disabled-background-color);
        border-color: nb-theme(select-filled-#{$status}-disabled-border-color);
        color: nb-theme(select-filled-#{$status}-disabled-text-color);

        nb-icon {
          color: nb-theme(select-filled-#{$status}-disabled-icon-color);
        }
      }

      nb-icon {
        color: nb-theme(select-filled-#{$status}-icon-color);
      }
    }
  }
}
