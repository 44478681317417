@mixin nd-flip-card-theme {
  .flip-button {
    line-height: nb-theme(card-text-line-height);
    margin-bottom: nb-theme(card-margin-bottom);
    padding: nb-theme(card-padding);
  }

  .flipcard-body .front-container {
    @include nb-ltr(margin-right, -100%);
    @include nb-rtl(margin-left, -100%);
  }
}
