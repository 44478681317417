@import '~@nebular/bootstrap/styles/globals';
@import 'themes';
@import '~@nebular/theme/styles/globals';
@import '~jsoneditor/dist/jsoneditor.min.css';

@include nb-install() {
  @include nb-bootstrap-global();
  @include nb-theme-global();
}

.ng2-smart-pagination .ng2-smart-page-item {
  display: flex !important; /* stylelint-disable-line declaration-no-important */
}

input[name='isAdmin'] {
  width: 20px !important; /* stylelint-disable-line declaration-no-important */
}

input[name='isAgent'] {
  width: 20px !important; /* stylelint-disable-line declaration-no-important */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(230deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.validation-error {
  color: nb-theme(color-danger-default);
  margin-top: 2px;
}

nb-toast .content-container {
  word-break: break-word;
}
