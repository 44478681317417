@mixin nd-reveal-card-theme {
  nb-reveal-card {
    box-shadow: nb-theme(card-shadow);
    margin-bottom: nb-theme(card-margin-bottom);
  }

  .second-card-container {
    height: 100%;
    border-radius: nb-theme(card-border-radius);
  }

  .reveal-button {
    line-height: nb-theme(card-text-line-height);
    padding: nb-theme(card-padding);
  }
}
