@mixin nb-list-theme() {
  nb-list-item {
    border-bottom:
      nb-theme(list-item-divider-width)
      nb-theme(list-item-divider-style)
      nb-theme(list-item-divider-color);

    color: nb-theme(list-item-text-color);
    font-family: nb-theme(list-item-font-family);
    font-size: nb-theme(list-item-font-size);
    font-weight: nb-theme(list-item-font-weight);
    line-height: nb-theme(list-item-line-height);
    padding: nb-theme(list-item-padding);

    &:first-child {
      border-top:
        nb-theme(list-item-divider-width)
        nb-theme(list-item-divider-style)
        nb-theme(list-item-divider-color);
    }
  }
}
